/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import store from '@/store/store.js'

export default [
   {
    header: 'Admin',
    icon: 'PackageIcon',
    i18n: 'Admin',
    items: [
      {
        url: '/admin/crm',
        name: 'CRM',
        slug: 'adminCRM',
        icon: 'UsersIcon',
        i18n: 'Admin CRM'
      },
      {
        url: '/admin/suppliers',
        name: 'Suppliers',
        slug: 'suppliers',
        icon: 'TruckIcon',
        i18n: 'Suppliers'
      },
      {
        url: '/admin/locationNormalisation',
        name: 'Location Normalisation',
        slug: 'locationNormalisation',
        icon: 'MapIcon',
        i18n: 'Location Normalisation'
      },
    ]
  }
]
